<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <DietSidebar/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">

        <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px; position: relative; width: 100%;">
            <div class="title">メディカルダイエット</div>
            <div class="LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=zFcyNv&liff_id=1657916768-pJgN5enw')">
              <div class="side-box">
                <img src="../../assets/SNS/line.png" style="height:50px">
                <div class="side-text-line">
                  <div style="font-size: 18px; text-align: center;">メディカルダイエット専用</div>
                  <div style="font-size: 18px; text-align: center;">お友達登録＋診療予約</div>
                </div>
              </div>
            </div>
          </div>

          

          <div class="separator">
            <div class="img_banner">
              <img style="width: 100%;" src="../../assets/departments/rwbanner.png">
              
              <div style="position:absolute;width: 40%;top: 20%;left:6%">
                <img style="width:70%;margin-left: 9%;" src="../../assets/departments/19b033_a27eb5902b7648d3acd54f27fdd1bf03~mv2.gif">
                <div class="banner_slogan slogan_big" style="margin-top: 12%;">GLP-1受容体作働薬</div>
                <div class="banner_slogan slogan_big">ストレスフリーダイエット</div>
                <div class="banner_slogan slogan_big_l">見た目が変われば人生が変わる</div>
              </div>
            </div>

            <div class="center-title" style="height: 60px;line-height: 2.7;">料　金</div>
            <div class="seperate_line"></div>
            <table class="table_ryoukin">
              <tr class="ryoukin_name_top">
                <td colspan="5"><h2>スターターキット ＋ リベルサス3mg/10錠　　¥2980（税込）</h2></td>
              </tr>
              <tr>
                <td class="ryoukin_hint" colspan="5">※単体での処方は出来ませんが、他のお薬と併用であれば処方できます</td>
              </tr>
              <tr>
                <td class="ryoukin_name">リベルサス®3mg(30錠)</td>
                <td class="ryoukin_name">¥7,500(税込)</td>
                <td style="width: 100px;"></td>
                <td class="ryoukin_name">リベルサス®7mg(30錠)</td>
                <td class="ryoukin_name">¥13,900(税込)</td>
              </tr>
              <tr>
                <td class="ryoukin_name">リベルサス®14mg(30錠)</td>
                <td class="ryoukin_name">¥24,800(税込)</td>
                <td></td>
                <td class="ryoukin_name">カナグル®100mg(30錠)</td>
                <td class="ryoukin_name">¥11,300(税込)</td>
              </tr>
              <tr>
                <td class="ryoukin_name">防風通聖散 90包(30日分)</td>
                <td class="ryoukin_name">¥5,300(税込)</td>
                <td style="border-bottom:2px solid #8A9297;"></td>
                <td style="border-bottom:2px solid #8A9297;"></td>
                <td style="border-bottom:2px solid #8A9297;"></td>
              </tr>
            </table>

            <div class="bg_black">
              <div class="side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">GLP-1受容体作働薬</div>
              </div>      
              <div class="content1"></div>
              <h1 class="center-title" style="color: #ebebeb;">あなたの体質を変えられるとしたら</h1>

              <div class="content1">
                <div class="side-text">
                  <div class="side-box-img">
                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <div class="title1">GLP-1受容体作動薬をご存知でしょうか？</div>

                      </div>
                      <div class="content_text">
                        <div class="text1">GLP-1は「痩せホルモン」ともよばれている、もともと人の体内に存在し腸から分泌されるホルモンのことで、主にインスリン分泌を促す働きをします。このGLP-1の分泌量が多いと食欲が抑えられ、脂肪が分解しやすいので太りにくく痩せやすいと言われています。逆にGLP-1の分泌量が少ないと、食欲が抑えられず食べ過ぎるので、結果的に太りやすくなります。</div>
                        <div class="text1">この痩せホルモンを毎日体内に取り込むだけで、体質を改善して自然にダイエットできるのが「GLP-1ダイエット」です</div>
                      </div>
                    </div>
                    <div class="side-img" style="width: 40%; margin-right: 0px">
                      <img src="../../assets/departments/rw02.png" class="fit_img">
                    </div>
                  </div>
                </div>
              </div>

              <div class="content1" style="height: 30px;"></div>
              <div class="center-title" style="color: #ebebeb;">GLP-1受容体作動薬とは小腸から分泌されるホルモン</div>
              <div class="content1">
                <div class="side-box">
                  <div class="side-text">
                    <div class="side-box">
                      <div class="side-line-highlight"></div>
                      <div class="title1">食欲をコントロールするホルモン GLP-1</div>
                    </div>
                    <div class="content_text">
                      <div class="text1">GLP-1とは、食事によって小腸から分泌されるホルモンの一種で、血糖値の上昇を抑えるインスリンの分泌を促します。また血糖値を上げるグルカゴンの分泌は抑えます。</div> 
                      <div class="text1">つまり血糖値を上がらないようにする薬剤、とお考え下さい。</div>   
                      <div class="text1">さらにGLP-1には胃の内容物の排出を遅らせ、また食欲を抑える働きもあるためダイエットに効果的な薬剤として広く用いられるようになりました。</div>                        
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="side-box" style="height: 300px; overflow: hidden;">
              <img src="../../assets/departments/rw (2).jpg" style="width: 100%;">
              <div style="position:absolute;width: 50%;left: 25%;">
                <div class=" slogan_large font_border" style="margin-top: 50px;">GLP-1受容体作動薬ダイエットの特徴</div>
                <div class=" slogan_big font_border">血糖を司るホルモン分泌をコントロールし、血糖上昇を抑える</div>
                <div class=" slogan_big font_border">見た目が変われば人生が変わる</div>
              </div>
            </div>

            <div class="bg_blue">
              <div class="side-text">
                <div class="side-box">
                  <div style="width: 60%;">
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">01.ストレスのない食事制限</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">食事制限ダイエット方法の場合、食欲を無理に抑えると、人によってはストレスになってしまいます。</div>
                      <div class="text1">GLP-1には食欲抑制効果があり、使用することで食べすぎを防ぎ、お腹がすきにくくなるため無駄な間食を防ぐことができます。</div>
                    </div>
                  </div>
                  <div class="side-img" style="width: 40%;margin-right: 0px">
                    <img src="../../assets/departments/diet/td01.jpg" class="fit_img">
                  </div>
                </div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="side-box">
                    <div class="side-img" style="width: 40%;margin-left: 0px">
                      <img src="../../assets/departments/diet/diet_05.jpg" class="fit_img">
                    </div>
                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <div class="title1">02.胃や腸に働きかけ糖の吸収を抑える</div>

                      </div>
                      <div class="content_text">
                        <div class="text1">GLP-1は胃の内容物をゆっくりと排出させる作用があり、糖の吸収も穏やかになる事で血糖値の上昇を抑えることができます。</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="side-box">
                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <div class="title1">03. 血糖値を下げる働きがある</div>

                      </div>
                      <div class="content_text">
                        <div class="text1">食事をとると一時的に血糖値が上昇しまが、その際にGLP-1が少ない方は、血糖値の上昇を抑制するインスリンの分泌が少ないため、血糖値をうまくコントロールすることができません。</div>
                        <div class="text1">GLP-1を投与することで血糖コントロールが改善されます。</div>
                      </div>
                    </div>
                    <div class="side-img" style="width: 40%;margin-right: 0px">
                      <img src="../../assets/departments/diet/td03.jpg" class="fit_img">
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="content1">
                <div class="side-text">
                  <div class="side-box">
                    <div class="side-img" style="width: 40%;margin-left: 0px">
                      <img src="../../assets/departments/diet/td04.jpg" class="fit_img">
                    </div>
                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <div class="title1">04. シンプルなダイエット方法</div>

                      </div>
                      <div class="content_text">
                        <div class="text1">錠剤タイプの：ＧＬＰ-１受容体作動薬ダイエットは１日１錠、起床時、空腹の状態で服用するだけの、理想的でシンプルなダイエット方法です。</div>
                      <div class="text1">そのため誰でも簡単に継続して行うことができます。</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>           

            </div>

            <div class="bg_green">
              <div class="side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">常用薬</div>
              </div>
              

              <div class="content1">
                <div class="side-text">
                  <div class="side-box">
                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <h2 class="title1">リベルサス：GLP-1受容体作動薬</h2>

                      </div>
                      <div class="content_text">
                        <div class="text1">多くの方がダイエットの経験があると思いますが、食事制限をしたり、無理な運動をすることは、精神的にストレスもあり、体にも負担が大きいので途中で断念してしまうことが多いです。GLP-1ホルモンを内服するダイエット方法では、1日1回の内服で自然に食欲が抑えられ、ダイエットで一番つらい「空腹感」を減少させ無理なくダイエットが続けられます。</div>
                        <div class="text1">それに加えてカナグルを一緒に内服すると、糖を吸収せず、尿から排出する働きがあるため、糖質が吸収されにくくなります。</div>
                      </div>                    
                    </div>
                    <div class="side-img" style="width: 40%; margin-right: 0px;">
                      <img src="../../assets/departments/diet/y01.jpg" class="fit_img">
                    </div>
                  </div>
                </div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="side-box">

                    <div class="side-img" style="width: 40%; margin-left: 0px">
                      <img src="../../assets/departments/diet/y02.jpg" class="fit_img">
                    </div>

                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>
                        <h2 class="title1">カナグル：SGLT2阻害薬</h2>
                      </div>
                      <div class="content_text">
                        <div class="text1">リベルサスと一緒にカナグルを内服することで、よりダイエット効果を高めることが期待できます。本来人間の体は、腎臓で血液から尿をつくる際に糖が尿に混ざってしまいます。しかし、腎臓で尿から糖を回収し、尿には糖が含まれない仕組みになっております。そこで、SGLT2阻害薬を内服することで、腎臓で尿から糖を回収する働きを阻害することで、糖が尿から排出されるようになります。</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>

              <div class="content1">
                <div class="side-text">
                  <div class="side-box">
                    <div style="width: 60%;">
                      <div class="side-box">
                        <div class="side-line-highlight"></div>

                        <h2 class="title1">防風通聖散：漢方薬</h2>

                      </div>
                      <div class="content_text">
                        <div class="text1">特に肥満症で便秘がちな人によく用いられ、"代謝・利尿・便通などの作用"により腹部の皮下脂肪を減らし、肥満症、便秘、肩こり、むくみなどに効果を表します。</div>
                        <div class="text1">皮下脂肪だけでなく内臓脂肪を燃焼させ、肥満を改善しやすい体質を作るのにも有効なお薬です。この18種類の生薬は、おなか周りの脂肪や肥満症に効果がある成分。</div>
                      </div>
                    </div>
                    <div class="side-img" style="width: 40%; margin-right: 0px">
                      <img src="../../assets/departments/diet/y03.jpg" class="fit_img">
                    </div>
                  </div>
                </div>
              </div>

              
            </div>

          </div>


        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>


  <div class="hidden-sm-and-up">
      <MDietSidebar/>
      <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
        <div class="side-box" style="margin-bottom: 30px; position: relative; width: 100%;">
          <div class="title">メディカルダイエット</div>
          
        </div>

        

        <div class="separator">
          <div class="m_LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=LzuXdt&liff_id=1657916768-pJgN5enw')">
            <div class="side-box">
              <img src="../../assets/SNS/line.png" style="height:50px">
              <div class="side-text-line">
                <div style="font-size: 15px; text-align: center;">メディカルダイエット専用</div>
                <div style="font-size: 15px; text-align: center;">お友達登録＋診療予約</div>
              </div>
            </div>
          </div>
          <div class="img_banner">
            <img style="width: 100%;" src="../../assets/departments/rwbanner.png">
            
            <div style="position:absolute;width: 40%;top: 20%;left:6%">
              <img style="width:70%;margin-left: 9%;" src="../../assets/departments/19b033_a27eb5902b7648d3acd54f27fdd1bf03~mv2.gif">
              <div class="banner_slogan m_slogan_big" style="margin-top: 12%;">GLP-1受容体作働薬</div>
              <div class="banner_slogan m_slogan_big">ストレスフリーダイエット</div>
              <div class="banner_slogan m_slogan_big_l">見た目が変われば人生が変わる</div>
            </div>
          </div>

          <div class="center-title" style="height: 60px;line-height: 2.7;">料　金</div>
          <div class="seperate_line"></div>
          <table class="m_table_ryoukin">
            <tr class="m_ryoukin_name_top">
              <td colspan="2">スターターキット ＋ リベルサス3mg/10錠　　¥2980（税込）</td>
            </tr>
            <tr>
              <td class="m_ryoukin_hint" colspan="2">※単体での処方は出来ませんが、他のお薬と併用であれば処方できます</td>
            </tr>
            <tr>
              <td class="m_ryoukin_name">リベルサス®3mg(30錠)</td>
              <td class="m_ryoukin_name">¥7,500(税込)</td>
            </tr>
            <tr>
              <td class="m_ryoukin_name">リベルサス®7mg(30錠)</td>
              <td class="m_ryoukin_name">¥13,900(税込)</td>
            </tr>
            <tr>
              <td class="m_ryoukin_name">リベルサス®14mg(30錠)</td>
              <td class="m_ryoukin_name">¥24,800(税込)</td>
            </tr>
            <tr>
              <td class="m_ryoukin_name">カナグル®100mg(30錠)</td>
              <td class="m_ryoukin_name">¥11,300(税込)</td>
            </tr>
            <tr>
              <td class="m_ryoukin_name">防風通聖散 90包(30日分)</td>
              <td class="m_ryoukin_name">¥5,300(税込)</td>
            </tr>
          </table>

          <div class="bg_black">
            <div class="m_side-text">
              <div class="slogan_big_l" style="color:#CBB8B2;">GLP-1受容体作働薬</div>
            </div>      
            <div class="content1"></div>
            <div class="m_side-text">
              <div class="center-title" style="color: #ebebeb;">あなたの体質を<br>変えられるとしたら</div>
            </div>
            

            <div class="content1">
              <div class="m_side-text">
                <div>
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">GLP-1受容体作動薬をご存知でしょうか？</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">GLP-1は「痩せホルモン」ともよばれている、もともと人の体内に存在し腸から分泌されるホルモンのことで、主にインスリン分泌を促す働きをします。このGLP-1の分泌量が多いと食欲が抑えられ、脂肪が分解しやすいので太りにくく痩せやすいと言われています。逆にGLP-1の分泌量が少ないと、食欲が抑えられず食べ過ぎるので、結果的に太りやすくなります。</div>
                      <div class="text1">この痩せホルモンを毎日体内に取り込むだけで、体質を改善して自然にダイエットできるのが「GLP-1ダイエット」です</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/rw02.png" class="fit_img">
                  </div>
                </div>
              </div>
            </div>

            <div class="content1" style="height: 30px;"></div>
            <div class="m_side-text">
            <div class="center-title" style="color: #ebebeb;">GLP-1受容体作動薬とは小腸から<br>分泌されるホルモン</div>
            </div>
            <div class="content1">
              <div class="side-box">
                <div class="m_side-text">
                  <div class="side-box">
                    <div class="side-line-highlight"></div>
                    <div class="title1">食欲をコントロールするホルモン GLP-1</div>
                  </div>
                  <div class="content_text">
                    <div class="text1">GLP-1とは、食事によって小腸から分泌されるホルモンの一種で、血糖値の上昇を抑えるインスリンの分泌を促します。また血糖値を上げるグルカゴンの分泌は抑えます。</div> 
                    <div class="text1">つまり血糖値を上がらないようにする薬剤、とお考え下さい。</div>   
                    <div class="text1">さらにGLP-1には胃の内容物の排出を遅らせ、また食欲を抑える働きもあるためダイエットに効果的な薬剤として広く用いられるようになりました。</div>                        
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="side-box" style="height: 200px; overflow: hidden;">
            <img src="../../assets/departments/rw (2).jpg" class="fit_img">
            <div style="position:absolute;width: 80%;left: 10%;">
              <div class=" m_slogan_large font_border" style="margin-top: 10px;">GLP-1受容体作動薬ダイエットの特徴</div>
              <div class=" m_slogan_big_l font_border">血糖を司るホルモン分泌をコントロールし、血糖上昇を抑える</div>
              <div class=" m_slogan_big_l font_border">見た目が変われば人生が変わる</div>
            </div>
          </div>

          <div class="bg_blue">
            <div class="m_side-text">
              <div>
                <div>
                  <div class="side-box">
                    <div class="side-line-highlight"></div>

                    <div class="title1">01.ストレスのない食事制限</div>

                  </div>
                  <div class="content_text">
                    <div class="text1">食事制限ダイエット方法の場合、食欲を無理に抑えると、人によってはストレスになってしまいます。</div>
                    <div class="text1">GLP-1には食欲抑制効果があり、使用することで食べすぎを防ぎ、お腹がすきにくくなるため無駄な間食を防ぐことができます。</div>
                  </div>
                </div>
                <div class="side-img">
                  <img src="../../assets/departments/diet/td01.jpg" class="fit_img">
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="m_side-text">
                <div>
                  
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">02.胃や腸に働きかけ糖の吸収を抑える</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">GLP-1は胃の内容物をゆっくりと排出させる作用があり、糖の吸収も穏やかになる事で血糖値の上昇を抑えることができます。</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/diet/diet_05.jpg" class="fit_img">
                  </div>
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="m_side-text">
                <div>
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">03. 血糖値を下げる働きがある</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">食事をとると一時的に血糖値が上昇しまが、その際にGLP-1が少ない方は、血糖値の上昇を抑制するインスリンの分泌が少ないため、血糖値をうまくコントロールすることができません。</div>
                      <div class="text1">GLP-1を投与することで血糖コントロールが改善されます。</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/diet/td03.jpg" class="fit_img">
                  </div>
                </div>
              </div>
            </div>
            
            <div class="content1">
              <div class="m_side-text">
                <div>
                  
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">04. シンプルなダイエット方法</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">錠剤タイプの：ＧＬＰ-１受容体作動薬ダイエットは１日１錠、起床時、空腹の状態で服用するだけの、理想的でシンプルなダイエット方法です。</div>
                    <div class="text1">そのため誰でも簡単に継続して行うことができます。</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/diet/td04.jpg" class="fit_img">
                  </div>
                  
                </div>
              </div>
            </div>           

          </div>

          <div class="bg_green">
            <div class="m_side-text">
              <div class="slogan_big_l" style="color:#CBB8B2;">常用薬</div>
            </div>
            

            <div class="content1">
              <div class="m_side-text">
                <div>
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">リベルサス：GLP-1受容体作動薬</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">多くの方がダイエットの経験があると思いますが、食事制限をしたり、無理な運動をすることは、精神的にストレスもあり、体にも負担が大きいので途中で断念してしまうことが多いです。GLP-1ホルモンを内服するダイエット方法では、1日1回の内服で自然に食欲が抑えられ、ダイエットで一番つらい「空腹感」を減少させ無理なくダイエットが続けられます。</div>
                      <div class="text1">それに加えてカナグルを一緒に内服すると、糖を吸収せず、尿から排出する働きがあるため、糖質が吸収されにくくなります。</div>
                    </div>                    
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/diet/y01.jpg" class="fit_img">
                  </div>
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="m_side-text">
                <div>
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>
                      <div class="title1">カナグル：SGLT2阻害薬</div>
                    </div>
                    <div class="content_text">
                      <div class="text1">リベルサスと一緒にカナグルを内服することで、よりダイエット効果を高めることが期待できます。本来人間の体は、腎臓で血液から尿をつくる際に糖が尿に混ざってしまいます。しかし、腎臓で尿から糖を回収し、尿には糖が含まれない仕組みになっております。そこで、SGLT2阻害薬を内服することで、腎臓で尿から糖を回収する働きを阻害することで、糖が尿から排出されるようになります。</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/diet/y02.jpg" class="fit_img">
                  </div>
                  
                </div>
              </div>
            </div>

            <div class="content1">
              <div class="m_side-text">
                <div>
                  <div>
                    <div class="side-box">
                      <div class="side-line-highlight"></div>

                      <div class="title1">防風通聖散：漢方薬</div>

                    </div>
                    <div class="content_text">
                      <div class="text1">特に肥満症で便秘がちな人によく用いられ、"代謝・利尿・便通などの作用"により腹部の皮下脂肪を減らし、肥満症、便秘、肩こり、むくみなどに効果を表します。</div>
                      <div class="text1">皮下脂肪だけでなく内臓脂肪を燃焼させ、肥満を改善しやすい体質を作るのにも有効なお薬です。この18種類の生薬は、おなか周りの脂肪や肥満症に効果がある成分。</div>
                    </div>
                  </div>
                  <div class="side-img">
                    <img src="../../assets/departments/diet/y03.jpg" class="fit_img">
                  </div>
                </div>
              </div>
            </div>

            
          </div>

        </div>


      </div>
    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import DietSidebar from "@/components/DietSidebar";
import MDietSidebar from "../../components/m/MDietSidebar";
export default {
  name: "Diet",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar,MDietSidebar},
  methods:{
    goHref(path) {
      window.location.href = path;
    },
  }
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.content1 {
      margin-top: 40px;
    }

    .content_text{
        margin-top: 18px;
    }
    .title1 {
      color: #CBB8B2;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .text1 {
      color: #ebebeb;
      line-height: 1.5;
      margin-bottom: 15px;
    }
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
      height:5px;
      border-top-color: #CBB8B2;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }
    

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #CBB8B2;
  border-bottom: 2px solid #CBB8B2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #CBB8B2;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.center-title{
  color: #535353;
  font-size: 24px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  position: absolute;
  right: 0px;
  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  cursor: pointer;
  transition: 150ms;
}

.LINE_btn:hover{
  background-color: #ffffff;
  color: #20ce88;
  box-shadow: 5px 5px 10px #42859b66,
  3px 3px 0px #20ce881b;
}
.m_LINE_btn{
  background-color: #20ce88;
  color: #FFFFFF;
  border-radius: 8px;
  width:80%;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}

.m_LINE_btn:active{
  background-color: #ffffff;
  color: #20ce88;
  border-radius: 8px;
  width:350px;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}

.banner_slogan{

  text-align: center;
  color: #326686;
  text-shadow: 1px 2px 0px #e9d5ce6b,
    0px 2px 3px #326686c4
    ;
  width: 100%;
}

.slogan_small{
      font-size: 15px;
      margin-top: 5px;
    }
    
    .slogan_big{
      font-size: 18px;
      margin-top: 15px;
    }
    
    .slogan_big_l{
      font-size: 30px;
      margin-top: 15px;
    }
    
    .slogan_large{
      font-size: 50px;  
    }
.m_slogan_small{
  font-size: 5px;
  margin-top: 5px;
}

.m_slogan_big{
  font-size: 10px;
  margin-top: 5px;
}

.m_slogan_big_l{
  font-size: 12px;
  margin-top: 5px;
}

.m_slogan_large{
  font-size: 20px;  
}

.font_border{
  text-align: center;
  color: #326686;
  text-shadow: 
    0px 1px 4px #f5efe3,
    0px -1px 4px #f5efe3,
    1px 0px 4px #f5efe3,
    -1px 0px 4px #f5efe3;
  width: 100%;
}

.img_banner{
  position: relative;
  height: auto;
}

.table_ryoukin{
  background-color: #fcfdff;
  color: #535353;
  width: 100%;
  margin: 0 auto;
  border-collapse:collapse;
}

.table_ryoukin tr{
  height:60px
}

.ryoukin_name_top{
  font-size: 24px;
  text-align: center;
}

.ryoukin_name{
  vertical-align: middle;
  border-bottom-color: #8A9297;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  

  
}

.ryoukin_hint{
  color: #F71B1B;
  font-size: 16px;
  text-align: right;
  padding-right: 50px;
  border-bottom:2px solid #8A9297;
}

.m_table_ryoukin{
  background-color: #fcfdff;
  color: #535353;
  width: 86%;
  margin: 0 auto;
  border-collapse:collapse;
  margin-left: 7%;
}

.m_table_ryoukin tr{
  height:40px
}

.m_ryoukin_name_top{
  font-size: 14px;
  text-align: center;
}

.m_ryoukin_name{
  font-size: 12px;
  vertical-align: middle;
  border-bottom-color: #8A9297;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.m_ryoukin_hint{
  color: #F71B1B;
  font-size: 12px;
  border-bottom:1px solid #8A9297;
}


.bg_black{
      background-color: #4d4f4f;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      height:auto;
  
    }
    
    .bg_blue{
      background-color: #326686;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      height: auto;
    }
    
    .bg_green{
      background-color: #5a7977;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      height: auto;
    }



    .title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

.m-title {
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}

.separator {
      color: #CBB8B2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

    .side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }

    .m_side-text{
      height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }

.side-text-line{
  height: fit-content;
}



.side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

.side-line-highlight{
  background-color: #CBB8B2;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}

</style>
